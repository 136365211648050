export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    //角色列表 
    rolesList: function rolesList(data) {
      return request({
        url: '/auth/roles',
        method: 'get',
        data: data
      });
    },
    // 获取路由权限列表
    addRule: function addRule(data) {
      return request({
        url: '/rule/add',
        method: 'post',
        data: data
      });
    },
    //修改角色状态 
    disableRole: function disableRole(data) {
      return request({
        url: '/rule/disableRole',
        method: 'get',
        data: data
      });
    },
    //修改角色 
    updRule: function updRule(data) {
      return request({
        url: '/rule/upRole',
        method: 'post',
        data: data
      });
    },
    //角色详情 
    ruleDetails: function ruleDetails(data) {
      return request({
        url: '/rule/upRole',
        method: 'get',
        data: data
      });
    },
    //删除角色 
    delRole: function delRole(data) {
      return request({
        url: '/rule/delRole',
        method: 'get',
        data: data
      });
    },
    // 获取路由权限列表
    ruleRouterList: function ruleRouterList(data) {
      return request({
        url: '/rule/add',
        method: 'get',
        data: data
      });
    }
  };
});