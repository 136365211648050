var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.formData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        modal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.rewriteClearData()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "80px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "名称", prop: "name" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入名称" },
                model: {
                  value: _vm.formData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "包名", prop: "pkg" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入包名" },
                model: {
                  value: _vm.formData.pkg,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "pkg", $$v)
                  },
                  expression: "formData.pkg"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "状态", prop: "status" }
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                _vm._l(_vm.statusLists, function(item, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: item.value, border: "" } },
                    [_vm._v(_vm._s(item.lable))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              staticStyle: { width: "100%" },
              attrs: { label: "图标", prop: "icon" }
            },
            [
              _c("uploadImage", {
                attrs: { value: _vm.imgList },
                on: { input: _vm.imgChange }
              })
            ],
            1
          ),
          _vm.formData.id
            ? _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "appid" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "lodingItem-inp ",
                      on: {
                        click: function($event) {
                          return _vm.copyText(_vm.formData.appid)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.formData.appid) + " ")]
                  )
                ]
              )
            : _vm._e(),
          _vm.formData.id
            ? _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { label: "appsecret" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "lodingItem-inp",
                      on: {
                        click: function($event) {
                          return _vm.copyText(_vm.formData.appsecret)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.formData.appsecret) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "margin-lef",
                      attrs: { type: "warning", size: "mini", round: "" },
                      on: { click: _vm.resetApp }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { round: "" },
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "warning", round: "" },
              on: {
                click: function($event) {
                  return _vm.rewriteClearData(false)
                }
              }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { slot: "reference", type: "primary", round: "" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit()
                }
              },
              slot: "reference"
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }