// 公司咨询
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    //咨询列表 
    businessInfo: function businessInfo() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/api/admin/business-infos',
        method: 'get',
        params: data
      });
    },
    //新增咨询 
    addBusinessInfo: function addBusinessInfo() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/api/admin/add-business-info',
        method: 'post',
        params: data
      });
    },
    //修改咨询 
    updBusinessInfo: function updBusinessInfo() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/api/admin/update-business-info',
        method: 'post',
        params: data
      });
    },
    // 删除咨询
    delBusinessInfo: function delBusinessInfo() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/api/admin/delete-business-info',
        method: 'post',
        params: data
      });
    }
  };
});