//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    item: {
      default: function _default() {
        return {};
      }
    },
    hoverMode: {
      default: false
    }
  }
};