export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    /**
     * 公告类型
     */
    // 公告类型列表
    AnnounceTypes: function AnnounceTypes() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announceType/announceTypeData',
        method: 'get',
        data: data
      });
    },
    // 新增公告类型
    addAnnounceType: function addAnnounceType() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announceType/announceTypeAdd',
        method: 'post',
        data: data
      });
    },
    // 修改公告类型
    updAnnounceType: function updAnnounceType() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announceType/announceTypeUpdate',
        method: 'post',
        data: data
      });
    },
    // 删除公告类型
    delAnnounceType: function delAnnounceType() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announceType/announceTypeDel',
        method: 'post',
        data: data
      });
    },
    // 获取所有公告类型
    announceTypeList: function announceTypeList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announceType/announceTypeDataAll',
        method: 'get',
        data: data
      });
    },
    // 公告排序
    announceTypeChangeSort: function announceTypeChangeSort() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announceType/announceTypeChangeSort',
        method: 'post',
        data: data
      });
    },

    /**
     * 公告列表
     */
    // 公告列表
    Announce: function Announce() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announce/announceData',
        method: 'get',
        data: data
      });
    },
    // 新增公告
    addAnnounce: function addAnnounce() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announce/announceAdd',
        method: 'post',
        data: data
      });
    },
    // 修改公告
    updAnnounce: function updAnnounce() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announce/announceUpdate',
        method: 'post',
        data: data
      });
    },
    // 删除公告
    delAnnounce: function delAnnounce() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announce/announceDel',
        method: 'post',
        data: data
      });
    },
    // 公告排序
    announceChangeSort: function announceChangeSort() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/announce/announceChangeSort',
        method: 'post',
        data: data
      });
    },
    // 轮播图
    bannerData: function bannerData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/banner/bannerData',
        method: 'get',
        data: data
      });
    },
    // 新增公告
    bannnerAdd: function bannnerAdd() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/banner/bannnerAdd',
        method: 'post',
        data: data
      });
    },
    // 修改公告
    bannerUpdate: function bannerUpdate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/banner/bannerUpdate',
        method: 'post',
        data: data
      });
    },
    // 删除公告
    bannerDel: function bannerDel() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/banner/bannerDel',
        method: 'post',
        data: data
      });
    },
    // 轮播图排序
    bannerChangeSort: function bannerChangeSort() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/banner/bannerChangeSort',
        method: 'post',
        data: data
      });
    },
    // 社交渠道
    socialData: function socialData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/social/socialData',
        method: 'get',
        data: data
      });
    },
    // 新增社交渠道
    socialAdd: function socialAdd() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/social/socialAdd',
        method: 'post',
        data: data
      });
    },
    // 修改社交渠道
    socialUpdate: function socialUpdate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/social/socialUpdate',
        method: 'post',
        data: data
      });
    },
    // 删除社交渠道
    socialDel: function socialDel() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/social/socialDel',
        method: 'post',
        data: data
      });
    },
    // 渠道排序
    socialChangeSort: function socialChangeSort() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/social/socialChangeSort',
        method: 'post',
        data: data
      });
    },
    // 下载地址配置
    downLoadData: function downLoadData() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/downloadLink/downLoadData',
        method: 'get',
        data: data
      });
    },
    // 新增社交渠道
    downLoadLinkAdd: function downLoadLinkAdd() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/downloadLink/downLoadLinkAdd',
        method: 'post',
        data: data
      });
    },
    // 修改社交渠道
    downloadLinkUpdate: function downloadLinkUpdate() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/downloadLink/downloadLinkUpdate',
        method: 'post',
        data: data
      });
    },
    // 删除社交渠道
    downloadLinkDel: function downloadLinkDel() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/downloadLink/downloadLinkDel',
        method: 'post',
        data: data
      });
    },
    // 社交渠道排序
    downloadLinkChangeSort: function downloadLinkChangeSort() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/downloadLink/downloadLinkChangeSort',
        method: 'post',
        data: data
      });
    },
    // 落地页数据
    recodeList: function recodeList() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'api/recode/recodeList',
        method: 'get',
        data: data
      });
    }
  };
});