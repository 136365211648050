export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 登录
    login: function login(data) {
      return request({
        url: '/login',
        method: 'post',
        data: data
      });
    },
    // 验证码
    getCode: function getCode(data) {
      return request({
        url: '/verification_code',
        method: 'get',
        data: data
      });
    },
    // 用户详情
    getInfo: function getInfo(data) {
      return request({
        url: '/user/userInfo',
        method: 'get',
        data: data
      });
    }
  };
});