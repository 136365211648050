var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newPopover" },
    [
      _c(
        "el-dialog",
        {
          staticStyle: { "pointer-events": "auto" },
          attrs: {
            title: _vm.title,
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            modal: false,
            "modal-append-to-body": false
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            },
            close: function($event) {
              return _vm.hide()
            }
          }
        },
        [
          _c("div", { staticClass: "dialogContent" }, [_vm._t("content")], 2),
          _vm.type == "purpose"
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.hide()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "info" },
                      on: {
                        click: function($event) {
                          return _vm.clearData()
                        }
                      }
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitData()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.confirmText || "提交"))]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.type == "download"
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitData()
                        }
                      }
                    },
                    [_vm._v("下载")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.type == "viewDetails"
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.submitData()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.confirmText || "确定"))]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }