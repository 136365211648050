import { render, staticRenderFns } from "./addgatewaySwitching.vue?vue&type=template&id=74ada5b7&scoped=true&"
import script from "./addgatewaySwitching.vue?vue&type=script&lang=js&"
export * from "./addgatewaySwitching.vue?vue&type=script&lang=js&"
import style0 from "./addgatewaySwitching.vue?vue&type=style&index=0&id=74ada5b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74ada5b7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\admin\\sdk_manage_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74ada5b7')) {
      api.createRecord('74ada5b7', component.options)
    } else {
      api.reload('74ada5b7', component.options)
    }
    module.hot.accept("./addgatewaySwitching.vue?vue&type=template&id=74ada5b7&scoped=true&", function () {
      api.rerender('74ada5b7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/gateway/components/addgatewaySwitching.vue"
export default component.exports