export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 邮件发送列表
    appEmailCodeConfigData: function appEmailCodeConfigData(data) {
      return request({
        url: '/appEmailCodeConfigList/appEmailCodeConfigData',
        method: 'get',
        data: data
      });
    },
    // 邮件发送新增
    appEmailCodeConfigAdd: function appEmailCodeConfigAdd(data) {
      return request({
        url: '/appEmailCodeConfigList/appEmailCodeConfigAdd',
        method: 'post',
        data: data
      });
    },
    // 邮件发送修改
    appEmailCodeConfigUpdate: function appEmailCodeConfigUpdate(data) {
      return request({
        url: '/appEmailCodeConfigList/appEmailCodeConfigUpdate',
        method: 'post',
        data: data
      });
    },
    // 邮件发送删除
    appEmailCodeConfigDel: function appEmailCodeConfigDel(data) {
      return request({
        url: '/appEmailCodeConfigList/appEmailCodeConfigDel',
        method: 'post',
        data: data
      });
    }
  };
});