export default {
  timestampToTime: function timestampToTime(timestamp, type) {
    var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

    if (type == 'YY-MM-DD') {
      return Y + M + D;
    } else {
      return Y + M + D + h + m + s;
    }
  },
  getStartEndTime: function getStartEndTime() {
    var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    // 一天的毫秒数
    var MillisecondsADay = 24 * 60 * 60 * num; // 今日开始时间戳

    var todayStartTime = new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000; // 今日结束时间戳

    var todayEndTime = parseInt(new Date(new Date().setHours(23, 59, 59, 999)).getTime() / 1000); // // 昨日开始时间戳
    // const yesterdayStartTime = todayStartTime - MillisecondsADay
    // // 昨日结束时间戳
    // const yesterdayEndTime = todayEndTime - MillisecondsADay
    // console.log(`
    //   ${todayStartTime} =>今日时间戳
    //   ${todayEndTime} => 结束时间戳
    //   ${yesterdayStartTime} =>  昨日开始时间戳
    //   ${yesterdayEndTime} =>  昨日结束时间戳
    // `)

    return [todayStartTime, todayEndTime];
  }
};