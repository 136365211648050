import "core-js/modules/es.object.to-string";
import _interopRequireWildcard from "D:/work/admin/sdk_manage_web/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import Vue from 'vue';
import d2Container from './d2-container'; // 注意 有些组件使用异步加载会有影响

Vue.component('d2-container', d2Container);
Vue.component('d2-icon', function () {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('./d2-icon'));
  });
});
Vue.component('d2-icon-svg', function () {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('./d2-icon-svg/index.vue'));
  });
});