export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 商品转换列表
    appPaymentGoodsData: function appPaymentGoodsData(data) {
      return request({
        url: '/appPaymentGoods/appPaymentGoodsData',
        method: 'get',
        data: data
      });
    },
    // 商品转换新增
    appPaymentGoodsAdd: function appPaymentGoodsAdd(data) {
      return request({
        url: '/appPaymentGoods/appPaymentGoodsAdd',
        method: 'post',
        data: data
      });
    },
    // 商品转换修改
    appPaymentGoodsUpdate: function appPaymentGoodsUpdate(data) {
      return request({
        url: '/appPaymentGoods/appPaymentGoodsUpdate',
        method: 'post',
        data: data
      });
    },
    // 商品转换删除
    appPaymentGoodsDel: function appPaymentGoodsDel(data) {
      return request({
        url: '/appPaymentGoods/appPaymentGoodsDel',
        method: 'post',
        data: data
      });
    }
  };
});