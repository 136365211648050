import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import _objectSpread from "D:/work/admin/sdk_manage_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from "dayjs";
import { mapActions } from "vuex";
export default {
  name: "login",
  data: function data() {
    return {
      timeInterval: null,
      time: dayjs().format("HH:mm:ss"),
      // admin 123456
      formLogin: {
        email: "",
        password: "",
        key: '',
        verification_code: ""
      },
      isLoading: null,
      url: '',
      key: '',
      rules: {
        email: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }],
        verification_code: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    this.timeInterval = setInterval(function () {
      _this.refreshTime();
    }, 1000);
    this.getCodeMeth();
    var that = this;

    document.onkeydown = function (event_e) {
      if (window.event) {
        event_e = window.event;
      }

      var int_keycode = event_e.charCode || event_e.keyCode;

      if (int_keycode == '13') {
        //回车键：13
        that.submit();
      }
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions("d2admin/account", ["login"])), {}, {
    refreshTime: function refreshTime() {
      this.time = dayjs().format("HH:mm:ss");
    },
    // 获取验证码
    getCodeMeth: function getCodeMeth() {
      var _this2 = this;

      this.$api.getCode().then(function (res) {
        if (res.status == 200) {
          _this2.url = res.data.url.img;
          _this2.formLogin.key = res.data.url.key;
          _this2.formLogin.verification_code = '';
        }
      });
    },
    submit: function submit() {
      var _this3 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.isLoading = true;

          _this3.$store.dispatch('d2admin/account/login', _this3.formLogin).then(function () {
            _this3.$router.replace(_this3.$route.query.redirect || "/");
          }).catch(function (err) {
            _this3.getCodeMeth();
          }).finally(function () {
            _this3.isLoading = false;
          });
        }
      });
    }
  })
};