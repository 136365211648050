var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      this.type == "url"
        ? _c("el-upload", {
            ref: "upload",
            staticStyle: { display: "none" },
            attrs: {
              action: _vm.uploadImgUrl,
              "list-type": "picture-card",
              "on-success": _vm.handleUploadSuccess,
              "on-error": _vm.handleUploadError,
              "on-exceed": _vm.handleExceed,
              name: "pic",
              "show-file-list": true,
              headers: _vm.headers,
              "file-list": _vm.fileList
            }
          })
        : _vm._e(),
      _c("div", { ref: "editor", staticClass: "editor", style: _vm.styles })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }