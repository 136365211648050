//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadMoreList, tool, commonData } from "@/mixins/index";
export default {
  name: "gatewayOrder",
  mixins: [loadMoreList, tool, commonData],
  data: function data() {
    return {
      datePicker: [],
      pagingReqFn: "gateOrderList",
      orderStatus: [{
        label: "创建",
        value: 1
      }, {
        label: "回调处理中",
        value: 2
      }, {
        label: "回调处理成功",
        value: 3
      }, {
        label: "回调处理失败",
        value: 4
      }],
      //订单状态
      deliverStatues: [{
        label: "默认创建",
        value: 1
      }, {
        label: "开始回调研发",
        value: 2
      }, {
        label: "回调研发成功",
        value: 3
      }, {
        label: "回调研发失败",
        value: 4
      }, {
        label: "回调次数超过允许值",
        value: 5
      }],
      // 分发状态
      dateTypeKey: 'month'
    };
  },
  created: function created() {
    this.getNowDate(this.dateType);
  },
  activated: function activated() {
    this.getcommonData(['appListAllData']);
  },
  methods: {}
};