import "core-js/modules/es.array.concat";
import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { ImageDrop } from 'quill-image-drop-module'; // 图片拖动组件引用

import imageResize from 'quill-image-resize-module'; // 图片缩放组件引用

Quill.register('modules/imageDrop', ImageDrop); // 注册

Quill.register('modules/imageResize', imageResize); // 注册

import util from '@/libs/util';
var fonts = ["SimSun", "SimHei", "Microsoft-YaHei", "KaiTi", "FangSong", "Arial", "Times-New-Roman", "sans-serif"];
var sizes = ["14px", "16px", "18px", "20px", "22px", "26px", "28px", "30px"];
var Size = Quill.import("formats/size");
Size.whitelist = sizes;
var Font = Quill.import("formats/font");
Font.whitelist = fonts;
Quill.register(Font, true);
import "./Editor.css";
export default {
  name: "Editor",
  props: {
    /* 编辑器的内容 */
    value: {
      type: String,
      default: ""
    },

    /* 高度 */
    height: {
      type: Number,
      default: null
    },

    /* 最小高度 */
    minHeight: {
      type: Number,
      default: null
    },

    /* 只读 */
    readOnly: {
      type: Boolean,
      default: false
    },
    // 上传文件大小限制(MB)
    fileSize: {
      type: Number,
      default: 2
    },

    /* 类型（base64格式、url格式） */
    type: {
      type: String,
      default: "url"
    }
  },
  data: function data() {
    return {
      Quill: null,
      currentValue: "",
      options: {
        theme: "snow",
        bounds: document.body,
        debug: "warn",
        modules: {
          imageDrop: true,
          //图片拖拽
          imageResize: {
            //放大缩小
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white"
            },
            modules: ["Resize", "DisplaySize", "Toolbar"]
          },
          // 工具栏配置
          toolbar: [["bold", "italic", "underline", "strike", "image"], // 加粗 斜体 下划线 删除线
          ["blockquote", "code-block"], // 引用  代码块
          [{
            list: "ordered"
          }, {
            list: "bullet"
          }], // 有序、无序列表
          [{
            indent: "-1"
          }, {
            indent: "+1"
          }], // 缩进
          [{
            size: sizes
          }], // 字体大小
          // [{ font: fonts }], //显示字体选择
          [{
            header: [1, 2, 3, 4, 5, 6, false]
          }], // 标题
          [{
            color: []
          }, {
            background: []
          }], // 字体颜色、字体背景颜色
          [{
            align: []
          }], // 对齐方式
          ["clean"], // 清除文本格式
          ["link"] // 链接、图片、视频
          ]
        },
        placeholder: "请输入内容",
        readOnly: this.readOnly
      },
      fileList: [],
      uploadType: '',
      uploadImgUrl: process.env.VUE_APP_API + "appLoginTypeConfig/uploadImg",
      // 上传的图片服务器地址
      headers: {
        Authorization: "Bearer " + util.cookies.get('token')
      }
    };
  },
  computed: {
    styles: function styles() {
      var style = {};

      if (this.minHeight) {
        style.minHeight = "".concat(this.minHeight, "px");
      }

      if (this.height) {
        style.height = "".concat(this.height, "px");
      }

      return style;
    }
  },
  watch: {
    value: {
      handler: function handler(val) {
        if (val !== this.currentValue) {
          this.currentValue = val === null ? "" : val;

          if (this.Quill) {
            this.Quill.pasteHTML(this.currentValue);
          }
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {
    this.Quill = null;
  },
  methods: {
    init: function init() {
      var _this = this;

      var editor = this.$refs.editor;
      this.Quill = new Quill(editor, this.options); // 如果设置了上传地址则自定义图片上传事件

      if (this.type == "url") {
        var toolbar = this.Quill.getModule("toolbar");
        toolbar.addHandler("image", function (value) {
          _this.uploadType = "image";

          if (value) {
            _this.$refs.upload.$children[1].$refs.input.click();
          } else {
            _this.quill.format("image", false);
          }
        });
      }

      this.Quill.pasteHTML(this.currentValue);
      this.Quill.on("text-change", function (delta, oldDelta, source) {
        var html = _this.$refs.editor.children[0].innerHTML;

        var text = _this.Quill.getText();

        var quill = _this.Quill;
        _this.currentValue = html;

        _this.$emit("input", html);

        _this.$emit("on-change", {
          html: html,
          text: text,
          quill: quill
        });
      });
      this.Quill.on("text-change", function (delta, oldDelta, source) {
        _this.$emit("on-text-change", delta, oldDelta, source);
      });
      this.Quill.on("selection-change", function (range, oldRange, source) {
        _this.$emit("on-selection-change", range, oldRange, source);
      });
      this.Quill.on("editor-change", function (eventName) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        _this.$emit.apply(_this, ["on-editor-change", eventName].concat(args));
      });
    },
    // 文件个数超出
    handleExceed: function handleExceed() {
      this.$message.error("\u4E0A\u4F20\u6587\u4EF6\u6570\u91CF\u4E0D\u80FD\u8D85\u8FC7 ".concat(this.limit, " \u4E2A!"));
    },
    handleUploadSuccess: function handleUploadSuccess(res, file) {
      // 获取富文本组件实例
      var quill = this.Quill; // 如果上传成功

      if (res) {
        var url = this.$utils.imgTrow(res.data); // 获取光标所在位置

        var length = quill.getSelection().index; // 插入图片  res.url为服务器返回的图片地址

        quill.insertEmbed(length, "image", url);
        quill.setSelection(length + 1);
      }
    },
    handleUploadError: function handleUploadError() {
      this.$message.error("图片插入失败");
    }
  }
};