var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticClass: "d2-ml-0 d2-mr btn-text can-hover",
          attrs: { type: "text" },
          on: {
            click: function($event) {
              _vm.dialogVisible = true
            }
          }
        },
        [
          _c("d2-icon", {
            staticStyle: { "font-size": "16px" },
            attrs: { name: "diamond" }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "主题",
            visible: _vm.dialogVisible,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [_c("d2-theme-list")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }