var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.formData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        modal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.clearDataTow()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "80px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "应用来源", prop: "from_appid" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  on: { change: _vm.fromAppidChange },
                  model: {
                    value: _vm.formData.from_appid,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "from_appid", $$v)
                    },
                    expression: "formData.from_appid"
                  }
                },
                _vm._l(_vm.appListAllData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      disabled: item.disabled,
                      label: item.name,
                      value: item.appid
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "应用切换", prop: "to_appid" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.formData.to_appid,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "to_appid", $$v)
                    },
                    expression: "formData.to_appid"
                  }
                },
                _vm._l(_vm.appListAllData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      disabled: item.disabled,
                      label: item.name,
                      value: item.appid
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { round: "" },
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "warning", round: "" },
              on: {
                click: function($event) {
                  return _vm.clearDataTow(false)
                }
              }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }