import _objectSpread from "D:/work/admin/sdk_manage_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
// 组件
import d2ContainerFull from './components/d2-container-full.vue';
import d2ContainerFullBs from './components/d2-container-full-bs.vue';
import d2ContainerGhost from './components/d2-container-ghost.vue';
import d2ContainerGhostBs from './components/d2-container-ghost-bs.vue';
import d2ContainerCard from './components/d2-container-card.vue';
import d2ContainerCardBs from './components/d2-container-card-bs.vue'; // import d2Source from './components/d2-source.vue'

export default {
  name: 'd2-container',
  props: {
    // 容器样式
    type: {
      type: String,
      required: false,
      default: 'full'
    },
    // 滚动优化
    betterScroll: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    // 始终返回渲染组件
    component: function component() {
      if (this.type === 'card' && !this.betterScroll) return d2ContainerCard;
      if (this.type === 'card' && this.betterScroll) return d2ContainerCardBs;
      if (this.type === 'ghost' && !this.betterScroll) return d2ContainerGhost;
      if (this.type === 'ghost' && this.betterScroll) return d2ContainerGhostBs;
      if (this.type === 'full' && !this.betterScroll) return d2ContainerFull;
      if (this.type === 'full' && this.betterScroll) return d2ContainerFullBs;else {
        return 'div';
      }
    }
  },
  render: function render(h) {
    var _this = this;

    var slots = [this.$slots.default, this.$slots.header ? h("template", {
      "slot": "header"
    }, [this.$slots.header]) : null, this.$slots.footer ? h("template", {
      "slot": "footer"
    }, [this.$slots.footer]) : null];
    return h("div", {
      "ref": "container",
      "class": "container-component"
    }, [h(this.component, {
      "ref": "component",
      "attrs": _objectSpread({}, this.$attrs),
      "on": {
        "scroll": function scroll(e) {
          return _this.$emit('scroll', e);
        }
      }
    }, [slots])]);
  },
  // <d2Source/>
  methods: {
    // 返回顶部
    scrollToTop: function scrollToTop() {
      this.$refs.component.scrollToTop(); // 如果开启了 better scroll 还需要手动触发一遍 scroll 事件

      var bs = this.$refs.component.BS;
      if (bs) this.$refs.component.scroll();
    },
    // 用法同原生方法 scrollBy
    scrollBy: function scrollBy() {
      var x = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var time = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 300;

      if (this.betterScroll) {
        var bs = this.$refs.component.BS;

        if (bs) {
          bs.scrollBy(-x, -y, time); // 手动触发一遍 scroll 事件

          this.$refs.component.scroll();
        }
      } else {
        this.$refs.component.$refs.body.scrollBy(x, y);
      }
    },
    // 用法同原生方法 scrollTo
    scrollTo: function scrollTo() {
      var x = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var time = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 300;

      if (this.betterScroll) {
        var bs = this.$refs.component.BS;

        if (bs) {
          bs.scrollTo(-x, -y, time); // 手动触发一遍 scroll 事件

          this.$refs.component.scroll();
        }
      } else {
        this.$refs.component.$refs.body.scrollTo(x, y);
      }
    },
    // 用法同原生方法 scrollTop
    scrollTop: function scrollTop() {
      var top = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var time = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 300;

      if (this.betterScroll) {
        var bs = this.$refs.component.BS;

        if (bs) {
          bs.scrollTo(bs.x, -top, time); // 手动触发一遍 scroll 事件

          this.$refs.component.scroll();
        }
      } else {
        this.$refs.component.$refs.body.scrollTop = top;
      }
    }
  }
};