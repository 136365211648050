var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-upload-image" },
    [
      _c(
        "el-upload",
        {
          class: { hide: this.fileList.length >= this.limit },
          attrs: {
            action: _vm.uploadImgUrl,
            "list-type": _vm.listType,
            "on-success": _vm.handleUploadSuccess,
            "before-upload": _vm.handleBeforeUpload,
            limit: _vm.limit,
            multiple: _vm.multiple,
            "on-error": _vm.handleUploadError,
            "on-exceed": _vm.handleExceed,
            name: "pic",
            "on-remove": _vm.handleRemove,
            "show-file-list": true,
            headers: _vm.headers,
            "file-list": _vm.fileList,
            "on-preview": _vm.handlePictureCardPreview,
            "on-change": _vm.handleChange
          }
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _vm.showTip
        ? _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip"
            },
            [
              _c("div", { staticClass: "y-start" }, [
                _c(
                  "div",
                  [
                    _vm._v(" 请上传 "),
                    _vm.fileSize
                      ? [
                          _vm._v(" 大小不超过 "),
                          _c("b", { staticStyle: { color: "#f56c6c" } }, [
                            _vm._v(_vm._s(_vm.fileSize) + "MB")
                          ])
                        ]
                      : _vm._e(),
                    _vm.fileType
                      ? [
                          _vm._v(" 格式为 "),
                          _c("b", { staticStyle: { color: "#f56c6c" } }, [
                            _vm._v(_vm._s(_vm.fileType.join("/")))
                          ])
                        ]
                      : _vm._e(),
                    _vm._v(" 的文件 ")
                  ],
                  2
                ),
                _vm.ExtraTips
                  ? _c("div", { staticClass: "ExtraTips" }, [
                      _vm._v("(" + _vm._s(_vm.ExtraTips) + ")")
                    ])
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _vm.dialogVisible
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeImgViewer,
              "url-list": [_vm.dialogImageUrl]
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }