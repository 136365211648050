import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import layoutHeaderAside from '@/layout/header-aside';
export function generaMenu(data, routes) {
  var res = [];
  var i = 1;
  data.forEach(function (item, index) {
    var menu = {
      path: item.path == '' ? item.id + '_key' : item.path,
      title: item.title,
      icon: item.icon || '',
      topLevel: i == 1 ? true : false
    };

    if (item.children && item.children.length) {
      i++;
      menu.children = generaMenu(item.children);
    }

    res.push(menu);
  });
  return res;
}
export function generaRouter(data, routes) {
  var res = [];
  data.forEach(function (item) {
    var menu = {
      path: item.path,
      name: item.name,
      component: item.path == '' ? layoutHeaderAside : function (resolve) {
        return require(["@/views".concat(item.path)], resolve);
      },
      children: [],
      meta: {
        title: item.title,
        id: item.id
      }
    };

    if (item.Children) {
      menu.Children = generaRouter(item.Children);
    }

    res.push(menu);
  });
  return res;
}
var state = {
  isFetchPermissionInfo: false
};
var mutations = {
  setFet: function setFet(state, data) {
    state.isFetchPermissionInfo = data;
  }
};
var actions = {
  generateAccessMenus: function generateAccessMenus(_ref, data) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      // 先查询后台并返回左侧菜单数据并把数据添加到路由
      var accessedMenus = generaMenu(data);
      resolve(accessedMenus);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};