export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 联运渠道列表
    appGatewayChannelListData: function appGatewayChannelListData(data) {
      return request({
        url: '/appGatewayChannelList/appGatewayChannelListData',
        method: 'get',
        data: data
      });
    },
    // 联运渠道新增
    appGatewayChannelListAdd: function appGatewayChannelListAdd(data) {
      return request({
        url: '/appGatewayChannelList/appGatewayChannelListAdd',
        method: 'post',
        data: data
      });
    },
    // 联运渠道修改
    appGatewayChannelListUpdate: function appGatewayChannelListUpdate(data) {
      return request({
        url: '/appGatewayChannelList/appGatewayChannelListUpdate',
        method: 'post',
        data: data
      });
    },
    // 联运渠道删除
    appGatewayChannelListDel: function appGatewayChannelListDel(data) {
      return request({
        url: '/appGatewayChannelList/appGatewayChannelListDel',
        method: 'post',
        data: data
      });
    },
    // 联运配置列表
    appGatewayChannelConfigData: function appGatewayChannelConfigData(data) {
      return request({
        url: '/appGatewayChannelConfig/appGatewayChannelConfigData',
        method: 'get',
        data: data
      });
    },
    // 联运配置新增
    appGatewayChannelConfigAdd: function appGatewayChannelConfigAdd(data) {
      return request({
        url: '/appGatewayChannelConfig/appGatewayChannelConfigAdd',
        method: 'post',
        data: data
      });
    },
    // 联运配置修改
    appGatewayChannelConfigUpdate: function appGatewayChannelConfigUpdate(data) {
      return request({
        url: '/appGatewayChannelConfig/appGatewayChannelConfigUpdate',
        method: 'post',
        data: data
      });
    },
    // 联运配置删除
    appGatewayChannelConfigDel: function appGatewayChannelConfigDel(data) {
      return request({
        url: '/appGatewayChannelConfig/appGatewayChannelConfigDel',
        method: 'post',
        data: data
      });
    },
    // 所有联运配置数据
    appGatewayChannelListAllData: function appGatewayChannelListAllData(data) {
      return request({
        url: '/appGatewayChannelList/appGatewayChannelListAllData',
        method: 'get',
        data: data
      });
    }
  };
});