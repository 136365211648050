var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "消息详情",
        visible: _vm.dialogVisible,
        modal: false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogContent" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.messageData.content) } }),
        _vm.messageData.file
          ? _c("div", { staticClass: "enclosure-box" }, [
              _c("div", { staticClass: "enclosure-boxTop" }, [
                _c("i", { staticClass: "el-icon-paperclip" }),
                _vm._v(" 附件 (1个) ")
              ]),
              _c("div", { staticClass: "enclosure-boxContent" }, [
                _vm._v(" " + _vm._s(_vm.fileName) + " "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "rgb(64, 158, 255)" },
                    attrs: { href: _vm.messageData.file, type: "primary" }
                  },
                  [_vm._v("下载")]
                )
              ])
            ])
          : _vm._e()
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "warning" }, on: { click: _vm.nextMesg } },
            [_vm._v("下一条")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }