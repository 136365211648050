var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: "详情",
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        modal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: { "label-width": "120px" }
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }