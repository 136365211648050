import "core-js/modules/es.number.constructor";
import { Message } from "element-ui";
export default {
  // 统一单位转换
  conversion: function conversion(num) {
    return Number(num) / 100;
  },
  textRepl: function textRepl(str) {
    var reple = process.env.VUE_APP_IMAGEURLRPLE;
    var url = process.env.VUE_APP_IMAGEURL;
    var newstr = str.replaceAll(reple, url + reple);
    return newstr;
  },
  imgTrow: function imgTrow(str) {
    var url = process.env.VUE_APP_IMAGEURL;
    return url + str;
  },
  capy: function capy(text) {
    //创建一个input框
    var input = document.createElement("input"); //将指定的DOM节点添加到body的末尾

    document.body.appendChild(input); //设置input框的value值为直播地址

    input.setAttribute("value", text); //选取文本域中的内容

    input.select(); //copy的意思是拷贝当前选中内容到剪贴板
    //document.execCommand（）方法操纵可编辑内容区域的元素
    //返回值为一个Boolean，如果是 false 则表示操作不被支持或未被启用

    if (document.execCommand("copy")) {
      document.execCommand("copy");
    } //删除这个节点


    document.body.removeChild(input);
    Message.success("复制成功");
  }
};