import "core-js/modules/es.object.to-string";
export default {
  data: function data() {
    return {
      formDataPrimitive: {},
      formDataMake: {}
    };
  },
  methods: {
    copyPrimitive: function copyPrimitive() {
      this.formDataPrimitive = JSON.parse(JSON.stringify(this.formData));
    },
    copyData: function copyData() {
      this.formDataMake = JSON.parse(JSON.stringify(this.formData));
    },
    clearData: function clearData() {
      var isClear = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.formData = JSON.parse(JSON.stringify(isClear ? this.formDataPrimitive : this.formDataMake));
      this.$refs.ruleForm && this.$refs.ruleForm.clearValidate();
    },
    // 提交二次确认
    secondConfirmation: function secondConfirmation() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        _this.$confirm('请确认当前操作?', '确认', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          resolve(true);
        });
      });
    }
  }
};