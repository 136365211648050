export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 应用列表
    appList: function appList(data) {
      return request({
        url: '/app/appListData',
        method: 'get',
        data: data
      });
    },
    // 新增应用
    appListAdd: function appListAdd(data) {
      return request({
        url: '/app/appListAdd',
        method: 'post',
        data: data
      });
    },
    // 修改应用
    appListUpdate: function appListUpdate(data) {
      return request({
        url: '/app/appListUpdate',
        method: 'post',
        data: data
      });
    },
    // 删除应用
    appListDel: function appListDel(data) {
      return request({
        url: '/app/appListDel',
        method: 'post',
        data: data
      });
    },
    // 获取所有应用
    appListAllData: function appListAllData(data) {
      return request({
        url: '/app/appListAllData',
        method: 'get',
        data: data
      });
    },
    // 重置secret
    resetApp: function resetApp(data) {
      return request({
        url: '/app/resetApp',
        method: 'post',
        data: data
      });
    }
  };
});