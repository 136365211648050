var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "custom-box",
        {
          staticStyle: { "pointer-events": "auto" },
          attrs: {
            title: "角色",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            "modal-append-to-body": true,
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: function($event) {
              return _vm.rewriteClearData()
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "popover",
              attrs: {
                rules: _vm.rules,
                model: _vm.formData,
                "label-width": "80px",
                "label-position": "left"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "title", label: "角色名称" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: {
                      placeholder: "请输入角色名称",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.formData.title,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "description", label: "角色描述" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: {
                      placeholder: "请输入角色描述",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.formData.description,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "description", $$v)
                      },
                      expression: "formData.description"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "listorder", label: "排序" }
                },
                [
                  _c("el-input", {
                    staticClass: "flex-1",
                    attrs: { placeholder: "请输入排序", autocomplete: "off" },
                    model: {
                      value: _vm.formData.listorder,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "listorder", $$v)
                      },
                      expression: "formData.listorder"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "listorder", label: "路由权限" }
                },
                [
                  _c("el-cascader", {
                    staticClass: "flex-1",
                    attrs: {
                      options: _vm.options,
                      props: _vm.props,
                      "collapse-tags": "",
                      clearable: "",
                      filterable: ""
                    },
                    model: {
                      value: _vm.valuearray,
                      callback: function($$v) {
                        _vm.valuearray = $$v
                      },
                      expression: "valuearray"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "lodingItem",
                  attrs: { prop: "status", label: "状态" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "flex-1",
                      model: {
                        value: _vm.formData.status,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "status", $$v)
                        },
                        expression: "formData.status"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1, border: "" } }, [
                        _vm._v("启用")
                      ]),
                      _c("el-radio", { attrs: { label: 0, border: "" } }, [
                        _vm._v("禁用")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { round: "" },
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.dataFormSubmit("ruleForm")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }