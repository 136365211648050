import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
export default {
  name: 'd2-contextmenu',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    }
  },
  computed: {
    flag: {
      get: function get() {
        if (this.visible) {
          // 注册全局监听事件 [ 目前只考虑鼠标解除触发 ]
          window.addEventListener('mousedown', this.watchContextmenu);
        }

        return this.visible;
      },
      set: function set(newVal) {
        this.$emit('update:visible', newVal);
      }
    },
    style: function style() {
      return {
        left: this.x + 'px',
        top: this.y + 'px',
        display: this.visible ? 'block' : 'none '
      };
    }
  },
  methods: {
    watchContextmenu: function watchContextmenu(event) {
      if (!this.$el.contains(event.target) || event.button !== 0) this.flag = false;
      window.removeEventListener('mousedown', this.watchContextmenu);
    }
  },
  mounted: function mounted() {
    // 将菜单放置到body下
    document.querySelector('body').appendChild(this.$el);
  }
};