export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 实名认证配置列表
    appRealnameAuthConfigData: function appRealnameAuthConfigData(data) {
      return request({
        url: '/appRealnameAuthConfigList/appRealnameAuthConfigData',
        method: 'get',
        data: data
      });
    },
    // 实名认证配置新增
    appRealnameAuthConfigAdd: function appRealnameAuthConfigAdd(data) {
      return request({
        url: '/appRealnameAuthConfigList/appRealnameAuthConfigAdd',
        method: 'post',
        data: data
      });
    },
    // 实名认证配置修改
    appRealnameAuthConfigUpdate: function appRealnameAuthConfigUpdate(data) {
      return request({
        url: '/appRealnameAuthConfigList/appRealnameAuthConfigUpdate',
        method: 'post',
        data: data
      });
    },
    // 实名认证配置删除
    appRealnameAuthConfigDel: function appRealnameAuthConfigDel(data) {
      return request({
        url: '/appRealnameAuthConfigList/appRealnameAuthConfigDel',
        method: 'post',
        data: data
      });
    }
  };
});