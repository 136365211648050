var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", { staticClass: "page" }, [
    _c("div", { staticClass: "x-c" }, [
      _c("img", {
        attrs: { src: require("../../../assets/images/BGTOW.png"), alt: "" }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }