import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { mapActions} from "vuex"
import { loadMoreList } from "@/mixins/index";
import addPaymentType from "./components/addPaymentType";
export default {
  name: "paymentTypeList",
  mixins: [loadMoreList],
  components: {
    addPaymentType: addPaymentType
  },
  data: function data() {
    return {
      pagingReqFn: "appPaymentTypeData",
      delIds: []
    };
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    // ...mapActions("d2admin/init", ["getDataList"]),
    handleSelectionChange: function handleSelectionChange(e) {
      this.delIds = e;
    },
    del: function del(dateList) {
      var _this = this;

      var ids = dateList.map(function (item) {
        return item.id;
      }).toString();
      this.$store.dispatch('d2admin/method/deleteList', {
        req: 'appPaymentTypeDel',
        data: {
          id: ids
        }
      }).then(function () {
        _this.getList(_this.paramsData.page);
      });
    }
  } // beforeRouteLeave(to, from, next) {
  //   this.getDataList('appPaymentTypeAllData')
  //   next()
  // },

};