export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 获取网站描述
    webDescriptions: function webDescriptions() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/api/admin/web-descriptions',
        method: 'get',
        params: data
      });
    },
    // 添加网站描述 
    addWebDescriptions: function addWebDescriptions() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/api/admin/add-web-description',
        method: 'post',
        params: data
      });
    },
    // 修改网站描述 
    updWebDescriptions: function updWebDescriptions() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/api/admin/update-web-description',
        method: 'post',
        params: data
      });
    },
    // 删除网站描述 
    delWebDescriptions: function delWebDescriptions() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/api/admin/delete-web-description',
        method: 'post',
        params: data
      });
    }
  };
});