export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    //图片上传 
    upload: function upload() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'get-oss-upload-params',
        method: 'get',
        params: data
      });
    },
    //上传配置 
    uploadParams: function uploadParams() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: 'get-oss-upload-params',
        method: 'get',
        params: data
      });
    }
  };
});