//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadMoreList, tool } from "@/mixins/index";
export default {
  name: "gatewayRealName",
  mixins: [loadMoreList, tool],
  data: function data() {
    return {
      pagingReqFn: "userRealNameList",
      dateType: 'arr'
    };
  },
  computed: {},
  created: function created() {},
  activated: function activated() {
    this.getNowDate(this.dateType);
  },
  methods: {}
};