var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      staticStyle: { "pointer-events": "auto" },
      attrs: {
        title: _vm.formData.id ? "修改" : "新增",
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
        "modal-append-to-body": true,
        modal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.rewriteClearData()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "80px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "名称", prop: "type_name" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入名称" },
                model: {
                  value: _vm.formData.type_name,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "type_name", $$v)
                  },
                  expression: "formData.type_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { label: "支付类型", prop: "p_type" }
            },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入支付类型" },
                model: {
                  value: _vm.formData.p_type,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "p_type", $$v)
                  },
                  expression: "formData.p_type"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              staticStyle: { width: "100%" },
              attrs: { label: "图标", prop: "icon" }
            },
            [
              _c("uploadImage", {
                attrs: { value: _vm.imgList },
                on: { input: _vm.imgChange }
              })
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-form-item",
                { staticClass: "lodingItem", attrs: { prop: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addContent }
                    },
                    [_vm._v("添加参数")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.formData.param_keys, function(domain, index) {
            return _c(
              "div",
              { key: index + "k", staticClass: "param_keysClass" },
              [
                _c(
                  "div",
                  { staticClass: "lodingItem-operationFrame flex" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "param_keys." + index + ".value",
                          rules: _vm.rules.value,
                          "label-width": "60px"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "operationFrame-entity",
                          attrs: { clearable: "", placeholder: "请输入" },
                          model: {
                            value: domain.value,
                            callback: function($$v) {
                              _vm.$set(domain, "value", $$v)
                            },
                            expression: "domain.value"
                          }
                        })
                      ],
                      1
                    ),
                    _c("el-button", {
                      staticStyle: { margin: "0 10px 24px 10px" },
                      attrs: {
                        size: "mini",
                        type: "danger",
                        circle: "",
                        icon: "el-icon-delete"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.removeContent(domain)
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { round: "" },
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "warning", round: "" },
              on: {
                click: function($event) {
                  return _vm.rewriteClearData(false)
                }
              }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit("ruleForm")
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }