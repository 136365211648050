//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'd2-contextmenu-list',
  props: {
    menulist: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    rowClick: function rowClick(event) {
      var target = event.target;

      while (!target.dataset.value) {
        target = target.parentNode;
      }

      this.$emit('rowClick', target.dataset.value);
    }
  }
};