var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d2-contentmenu-list", on: { click: _vm.rowClick } },
    _vm._l(_vm.menulist, function(item) {
      return _c(
        "div",
        {
          key: item.value,
          staticClass: "d2-contentmenu-item",
          attrs: { "data-value": item.value, flex: "cross:center main:center" }
        },
        [
          item.icon ? _c("d2-icon", { attrs: { name: item.icon } }) : _vm._e(),
          _c(
            "div",
            {
              staticClass: "d2-contentmenu-item-title",
              attrs: { "flex-box": "1" }
            },
            [_vm._v(" " + _vm._s(item.title) + " ")]
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }