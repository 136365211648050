import "core-js/modules/es.array.map";
import _objectSpread from "D:/work/admin/sdk_manage_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { uniqueId } from 'lodash';
/**
 * @description 给菜单数据补充上 path 字段
 * @description https://github.com/d2-projects/d2-admin/issues/209
 * @param {Array} menu 原始的菜单数据
 */

function supplementPath(menu) {
  return menu.map(function (e) {
    return _objectSpread(_objectSpread({}, e), {}, {
      path: e.path || uniqueId('d2-menu-empty-')
    }, e.children ? {
      children: supplementPath(e.children)
    } : {});
  });
}

export var menuHeader = supplementPath([{
  path: '/index',
  title: '首页',
  icon: 'home'
}]);
export var menuAside = supplementPath([{
  path: '/index',
  title: '首页',
  icon: 'home'
}]);