import "core-js/modules/es.object.to-string";
// 上拉加载更多列表
import api from '@/api';
import pickerOption from './pickerOption.js';
export default {
  mixins: [pickerOption],
  data: function data() {
    return {
      isLoading: false,
      // 总条数
      statusList: [{
        lable: '正常',
        value: 1
      }, {
        lable: '禁用',
        value: 2
      }, {
        lable: '审核中',
        value: 3
      }],
      total: 0,
      tableData: [],
      // 列表请求方法
      pagingReqFn: null,
      // 请求中存放列表的字段
      pagingReqValue: 'data',
      // 请求或搜索存放请求参数
      paramsData: {
        page_size: 10,
        page: 1
      }
    };
  },
  methods: {
    /**
     * 请求列表方法
     * @param page 页码
     * @param pagingCallback 回调函数
     * **/
    getList: function getList() {
      var _this = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var pagingCallback = arguments.length > 1 ? arguments[1] : undefined;
      this.paramsData.page = page;

      if (!this.pagingReqFn) {
        console.error('请传入请求函数');
        return false;
      }

      this.isLoading = true;
      api[this.pagingReqFn](this.paramsData).then(function (res) {
        if (res.status == 200) {
          if (page > 1 && !res.data.data.length) {
            _this.getList(page - 1);
          } else {
            var data = res.data;
            _this.total = data.total || 0;
            _this.tableData = _this.pagingReqValue ? data[_this.pagingReqValue] || [] : data || [];
          }
        }
      }).finally(function () {
        _this.isLoading = false;
      });
    }
  }
};