var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d2-panel-search-item",
      class: _vm.hoverMode ? "can-hover" : "",
      attrs: { flex: "" }
    },
    [
      _c(
        "div",
        {
          staticClass: "d2-panel-search-item__icon",
          attrs: { "flex-box": "0" }
        },
        [
          _c(
            "div",
            {
              staticClass: "d2-panel-search-item__icon-box",
              attrs: { flex: "main:center cross:center" }
            },
            [
              _vm.item.icon
                ? _c("d2-icon", { attrs: { name: _vm.item.icon } })
                : _vm.item.iconSvg
                ? _c("d2-icon-svg", { attrs: { name: _vm.item.iconSvg } })
                : _c("d2-icon", { attrs: { name: "file-o" } })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d2-panel-search-item__info",
          attrs: { "flex-box": "1", flex: "dir:top" }
        },
        [
          _c(
            "div",
            {
              staticClass: "d2-panel-search-item__info-title",
              attrs: { "flex-box": "1", flex: "cross:center" }
            },
            [_c("span", [_vm._v(_vm._s(_vm.item.title))])]
          ),
          _c(
            "div",
            {
              staticClass: "d2-panel-search-item__info-fullTitle",
              attrs: { "flex-box": "0" }
            },
            [_c("span", [_vm._v(_vm._s(_vm.item.fullTitle))])]
          ),
          _c(
            "div",
            {
              staticClass: "d2-panel-search-item__info-path",
              attrs: { "flex-box": "0" }
            },
            [_c("span", [_vm._v(_vm._s(_vm.item.path))])]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }