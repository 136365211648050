var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.$refs.addRole.init()
                }
              }
            },
            [_vm._v("添加角色")]
          )
        ],
        1
      ),
      _vm.tableData.length
        ? _c(
            "el-table",
            { attrs: { data: _vm.tableData, border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "60" }
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "角色名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "listorder", label: "排序" }
              }),
              _c("el-table-column", {
                attrs: { prop: "description", label: "角色描述" }
              }),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.status == 0
                            ? _c("span", { staticClass: "statusTag1" }, [
                                _vm._v("禁用")
                              ])
                            : _vm._e(),
                          row.status == 1
                            ? _c("span", { staticClass: "statusTag2" }, [
                                _vm._v("正常")
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4134895650
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "160",
                  align: "center",
                  fixed: "right"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("div", { staticClass: "x-c" }, [
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                on: {
                                  click: function($event) {
                                    return _vm.handleDisable(scope.row.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.status == 0 ? "启用" : "禁用"
                                  )
                                )
                              ]
                            ),
                            _c("div", { staticClass: "controlsBtn-vertical" }),
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.addRole.init(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            ),
                            _c("div", { staticClass: "controlsBtn-vertical" }),
                            _c(
                              "div",
                              {
                                staticClass: "controlsBtn",
                                on: {
                                  click: function($event) {
                                    return _vm.handleDelete(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1922014961
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.tableData.length
        ? _c("el-pagination", {
            attrs: {
              layout: "total, prev, pager, next",
              "current-page": _vm.paramsData.page,
              total: _vm.total,
              "page-size": 10
            },
            on: { "current-change": _vm.getList }
          })
        : _vm._e(),
      _c("addRole", {
        ref: "addRole",
        on: {
          change: function($event) {
            return _vm.getList(_vm.paramsData.page)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }