export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 用户列表
    userList: function userList(data) {
      return request({
        url: '/auth/users',
        method: 'get',
        data: data
      });
    },
    // 用户详情
    userDetails: function userDetails(data) {
      return request({
        url: '/user/upUser',
        method: 'get',
        data: data
      });
    },
    // 添加用户
    userAddList: function userAddList(data) {
      return request({
        url: '/user/add',
        method: 'get',
        data: data
      });
    },
    // 添加用户
    userAdd: function userAdd(data) {
      return request({
        url: '/user/add',
        method: 'post',
        data: data
      });
    },
    // 修改用户
    userUpd: function userUpd(data) {
      return request({
        url: '/user/upUser',
        method: 'post',
        data: data
      });
    },
    // 修改用户状态
    disableUser: function disableUser(data) {
      return request({
        url: '/user/disableUser',
        method: 'get',
        data: data
      });
    },
    // 删除用户
    delUser: function delUser(data) {
      return request({
        url: '/user/delUser',
        method: 'get',
        data: data
      });
    }
  };
});