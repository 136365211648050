//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import scroll from './mixins/normal';
export default {
  name: 'd2-container-full',
  mixins: [scroll],
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    // 增加滚动事件监听
    this.addScrollListener();
  },
  beforeDestroy: function beforeDestroy() {
    // 移除滚动事件监听
    this.removeScrollListener();
  }
};