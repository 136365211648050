export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 获取所有登录类型
    appLoginTypeRes: function appLoginTypeRes(data) {
      return request({
        url: '/appLogin/appLoginTypeRes',
        method: 'get',
        data: data
      });
    },
    // 登录类型列表
    appLoginTypeData: function appLoginTypeData(data) {
      return request({
        url: '/appLogin/appLoginTypeData',
        method: 'get',
        data: data
      });
    },
    // 登陆类型新增
    appLoginTypeAdd: function appLoginTypeAdd(data) {
      return request({
        url: '/appLogin/appLoginTypeAdd',
        method: 'post',
        data: data
      });
    },
    // 登陆类型修改
    appLoginTypeUpdate: function appLoginTypeUpdate(data) {
      return request({
        url: '/appLogin/appLoginTypeUpdate',
        method: 'post',
        data: data
      });
    },
    // 登陆类型删除
    appLoginTypeDel: function appLoginTypeDel(data) {
      return request({
        url: '/appLogin/appLoginTypeDel',
        method: 'post',
        data: data
      });
    },
    // 登录类型配置列表
    appLoginTypeConfigData: function appLoginTypeConfigData(data) {
      return request({
        url: '/appLoginTypeConfig/appLoginTypeConfigData',
        method: 'get',
        data: data
      });
    },
    // 登录类型配置列表
    appLoginTypeConfigDataNoPage: function appLoginTypeConfigDataNoPage(data) {
      return request({
        url: '/appLoginTypeConfig/appLoginTypeConfigDataNoPage',
        method: 'get',
        data: data
      });
    },
    // 登陆类型配置新增
    appLoginTypeConfigAdd: function appLoginTypeConfigAdd(data) {
      return request({
        url: '/appLoginTypeConfig/appLoginTypeConfigAdd',
        method: 'post',
        data: data
      });
    },
    // 登陆类型配置修改
    appLoginTypeConfigUpd: function appLoginTypeConfigUpd(data) {
      return request({
        url: '/appLoginTypeConfig/appLoginTypeConfigUpdate',
        method: 'post',
        data: data
      });
    },
    // 登陆类型配置删除
    appLoginTypeConfigDel: function appLoginTypeConfigDel(data) {
      return request({
        url: '/appLoginTypeConfig/appLoginTypeConfigDel',
        method: 'post',
        data: data
      });
    },
    // 登录配置列表 
    appLoginSdkConfigData: function appLoginSdkConfigData(data) {
      return request({
        url: '/apLoginSdkConfig/appLoginSdkConfigData',
        method: 'get',
        data: data
      });
    },
    // 登陆配置新增
    appLoginSdkConfigAdd: function appLoginSdkConfigAdd(data) {
      return request({
        url: '/apLoginSdkConfig/appLoginSdkConfigAdd',
        method: 'post',
        data: data
      });
    },
    // 登陆配置修改
    appLoginSdkConfigUpdate: function appLoginSdkConfigUpdate(data) {
      return request({
        url: '/apLoginSdkConfig/appLoginSdkConfigUpdate',
        method: 'post',
        data: data
      });
    },
    // 登陆配置删除
    appLoginSdkConfigDel: function appLoginSdkConfigDel(data) {
      return request({
        url: '/apLoginSdkConfig/appLoginSdkConfigDel',
        method: 'post',
        data: data
      });
    },
    // 重置
    resetLogin: function resetLogin(data) {
      return request({
        url: '/apLoginSdkConfig/resetLogin',
        method: 'post',
        data: data
      });
    },
    // 登录参数切换列表
    appLoginReplaceListData: function appLoginReplaceListData(data) {
      return request({
        url: '/appLoginReplaceList/appLoginReplaceListData',
        method: 'get',
        data: data
      });
    },
    // 登录参数切换新增
    appLoginReplaceListAdd: function appLoginReplaceListAdd(data) {
      return request({
        url: '/appLoginReplaceList/appLoginReplaceListAdd',
        method: 'post',
        data: data
      });
    },
    // 登录参数切换修改
    appLoginReplaceListUpdate: function appLoginReplaceListUpdate(data) {
      return request({
        url: '/appLoginReplaceList/appLoginReplaceListUpdate',
        method: 'post',
        data: data
      });
    },
    // 登录参数切换删除
    appLoginReplaceListDel: function appLoginReplaceListDel(data) {
      return request({
        url: '/appLoginReplaceList/appLoginReplaceListDel',
        method: 'post',
        data: data
      });
    },
    // 用户列表
    LoginDatauserData: function LoginDatauserData(data) {
      return request({
        url: '/sdkLoginDataList/userData',
        method: 'get',
        data: data
      });
    },
    // 修改用户状态
    loginUserBan: function loginUserBan(data) {
      return request({
        url: '/sdkLoginDataList/loginUserBan',
        method: 'post',
        data: data
      });
    },
    // 账号实名列表
    userRealNameList: function userRealNameList(data) {
      return request({
        url: '/sdkLoginDataList/userRealNameList',
        method: 'get',
        data: data
      });
    },
    // 账号登录列表
    accountList: function accountList(data) {
      return request({
        url: '/sdkLoginDataList/accountList',
        method: 'get',
        data: data
      });
    },
    // 邮箱登录列表
    emailList: function emailList(data) {
      return request({
        url: '/sdkLoginDataList/emailList',
        method: 'get',
        data: data
      });
    },
    // 第三方登录列表
    oauthList: function oauthList(data) {
      return request({
        url: '/sdkLoginDataList/oauthList',
        method: 'get',
        data: data
      });
    },
    // 手机登录列表
    phoneList: function phoneList(data) {
      return request({
        url: '/sdkLoginDataList/phoneList',
        method: 'get',
        data: data
      });
    },
    // 游客登录详情
    visitorList: function visitorList(data) {
      return request({
        url: '/sdkLoginDataList/visitorList',
        method: 'get',
        data: data
      });
    }
  };
});