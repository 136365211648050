import _objectSpread from "D:/work/admin/sdk_manage_web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  name: "registration",
  props: {
    title: String,
    type: {
      type: String,
      default: function _default() {
        return 'purpose';
      }
    },
    confirmText: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  computed: _objectSpread({}, mapState('d2admin', {
    device: function device(state) {
      return state.menu.device;
    },
    oledShowDialog: function oledShowDialog(state) {
      return state.method.showDialog;
    }
  })),
  data: function data() {
    return {
      showDialog: false
    };
  },
  created: function created() {},
  activated: function activated() {
    this.$store.dispatch('d2admin/method/set', this.oledShowDialog ? this.oledShowDialog : this.showDialog);
  },
  deactivated: function deactivated() {
    this.$store.dispatch('d2admin/method/set', false);
  },
  methods: {
    open: function open() {
      this.$store.dispatch('d2admin/method/set', true);
      this.showDialog = true;
    },
    hide: function hide() {
      this.$store.dispatch('d2admin/method/set', false);
      this.showDialog = false;
      this.$emit('dlodingHide');
    },
    clearData: function clearData() {
      // this.$store.dispatch('d2admin/method/set', false)
      this.$emit('clearData');
    },
    submitData: function submitData() {
      this.$emit('submitData');
    }
  }
};