import "D:\\work\\admin\\sdk_manage_web\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\work\\admin\\sdk_manage_web\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\work\\admin\\sdk_manage_web\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\work\\admin\\sdk_manage_web\\node_modules\\core-js\\modules\\es.promise.finally.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App'; // 核心插件

import d2Admin from '@/plugin/d2admin'; // store

import store from '@/store/index'; // 防抖

import lodash from 'lodash'; // 菜单和路由设置

import times from '@/utils/changTime';
import utils from '@/utils/utils';
import newPopover from "@/components/newPopover";
import uploadImage from "@/components/uploadImage";
Vue.component('newPopover', newPopover); // 弹窗组件

import customBox from "@/components/customBox";
Vue.component('custom-box', customBox); // 注册指令

var bus = new Vue();
Vue.prototype.$bus = bus;
import { serchSelect } from "@/mixins/index.js"; // import {tool} from "@/mixins/index.js"

Vue.mixin(serchSelect); // Vue.mixin(tool)

var Base64 = require('js-base64').Base64;

Vue.use(Base64);
Vue.prototype.$times = times;
Vue.prototype.$utils = utils;
import router from './router';
import { Message } from 'element-ui';
Vue.prototype.$UPIMAGE = process.env.VUE_APP_API + "/upload-pics";
Vue.prototype.$IMAGEURL = process.env.VUE_APP_IMGURL;
Vue.prototype.$Message = Message;
Vue.prototype.$lodash = lodash;
Vue.prototype.$imgUrl = process.env.VUE_APP_IMAGEURL; // 全局组件挂载

Vue.component('uploadImage', uploadImage); // mixins
// import { event } from "@/mixins/index"
// Vue.mixin(event)
// 复制插件

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
import VueParticles from 'vue-particles';
Vue.use(VueParticles); // 核心插件

Vue.use(d2Admin, {
  store: store
});
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {},
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow'); // 用户登录后从数据库加载一系列的设置

    this.$store.dispatch('d2admin/account/load'); // 获取并记录用户 UA

    this.$store.commit('d2admin/ua/get'); // 初始化全屏监听

    this.$store.dispatch('d2admin/fullscreen/listen');
  }
}).$mount('#app');