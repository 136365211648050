export default {
  data: function data() {
    return {
      language: [{
        label: '简体中文',
        value: 'cn'
      }, {
        label: '繁体中文',
        value: 'tc'
      }, {
        label: '英文',
        value: 'en'
      }],
      AnnouncemenTypeList: [{
        label: '系统公告',
        value: 'sys'
      }, {
        label: '游戏公告',
        value: 'game'
      }]
    };
  },
  methods: {}
};