//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { loadMoreList, tool, commonData } from "@/mixins/index";
export default {
  name: "payOrderList",
  mixins: [loadMoreList, tool, commonData],
  data: function data() {
    return {
      pagingReqFn: "payOrderList",
      dateTypeKey: 'month',
      orderStatus: [{
        label: "订单被创建",
        value: 1
      }, {
        label: "订单被回调",
        value: 2
      }, {
        label: "回调处理成功",
        value: 3
      }, {
        label: "回调处理失败",
        value: 4
      }, {
        label: "预下单成功",
        value: 5
      }, {
        label: "订单成功",
        value: 6
      }],
      deliverStatus: [{
        label: "未回调网关",
        value: 1
      }, {
        label: "开始回调网关",
        value: 2
      }, {
        label: "回调网关成功",
        value: 3
      }, {
        label: "回调网关失败",
        value: 4
      }, {
        label: "回调网关次数超过允许值",
        value: 5
      }]
    };
  },
  created: function created() {
    this.getNowDate(this.dateType);
  },
  activated: function activated() {
    this.getcommonData(['appListAllData']);
  },
  methods: {}
};