export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 登录
    appPhoneCodeConfigData: function appPhoneCodeConfigData(data) {
      return request({
        url: '/appPhoneCodeConfigList/appPhoneCodeConfigData',
        method: 'get',
        data: data
      });
    },
    // 登录
    appPhoneCodeConfigAdd: function appPhoneCodeConfigAdd(data) {
      return request({
        url: '/appPhoneCodeConfigList/appPhoneCodeConfigAdd',
        method: 'post',
        data: data
      });
    },
    // 验证码
    appPhoneCodeConfigUpdate: function appPhoneCodeConfigUpdate(data) {
      return request({
        url: '/appPhoneCodeConfigList/appPhoneCodeConfigUpdate',
        method: 'post',
        data: data
      });
    },
    // 用户详情
    appPhoneCodeConfigDel: function appPhoneCodeConfigDel(data) {
      return request({
        url: '/appPhoneCodeConfigList/appPhoneCodeConfigDel',
        method: 'post',
        data: data
      });
    }
  };
});