import "core-js/modules/es.function.name";
//
//
//
//
//
//
export default {
  name: 'd2-icon-svg',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  computed: {
    icon: function icon() {
      return "#d2-".concat(this.name);
    }
  }
};