import "core-js/modules/es.array.concat";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);
/**
 * 在主框架内显示
 */


var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [// 首页
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('system/index')
  }, // 系统 前端日志
  {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}];
/**
 * 在主框架之外显示
 */

var frameOut = [// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 重新组织后导出

export default [].concat(frameIn, frameOut, errorPage);