export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 获取所有支付类型
    appPaymentTypeAllData: function appPaymentTypeAllData(data) {
      return request({
        url: '/appPay/appPaymentTypeAllData',
        method: 'get',
        data: data
      });
    },
    // 支付类型列表
    appPaymentTypeData: function appPaymentTypeData(data) {
      return request({
        url: '/appPay/appPaymentTypeData',
        method: 'get',
        data: data
      });
    },
    // 新增支付类型
    appPaymentTypeAdd: function appPaymentTypeAdd(data) {
      return request({
        url: '/appPay/appPaymentTypeAdd',
        method: 'post',
        data: data
      });
    },
    // 修改支付类型
    appPaymentTypeUpdate: function appPaymentTypeUpdate(data) {
      return request({
        url: '/appPay/appPaymentTypeUpdate',
        method: 'post',
        data: data
      });
    },
    // 删除支付类型
    appPaymentTypeDel: function appPaymentTypeDel(data) {
      return request({
        url: '/appPay/appPaymentTypeDel',
        method: 'post',
        data: data
      });
    },
    // 支付类型配置列表
    appPaymentConfigData: function appPaymentConfigData(data) {
      return request({
        url: '/appPaymentTypeConfig/appPaymentTypeConfigData',
        method: 'get',
        data: data
      });
    },
    // 支付类型配置新增
    appPaymentTypeConfigAdd: function appPaymentTypeConfigAdd(data) {
      return request({
        url: '/appPaymentTypeConfig/appPaymentTypeConfigAdd',
        method: 'post',
        data: data
      });
    },
    // 支付类型配置修改
    appPaymentTypeConfigUpdate: function appPaymentTypeConfigUpdate(data) {
      return request({
        url: '/appPaymentTypeConfig/appPaymentTypeConfigUpdate',
        method: 'post',
        data: data
      });
    },
    // 支付类型配置删除
    appPaymentTypeConfigDel: function appPaymentTypeConfigDel(data) {
      return request({
        url: '/appPaymentTypeConfig/appPaymentTypeConfigDel',
        method: 'post',
        data: data
      });
    },
    // 支付参数切换列表
    appPaymentReplaceListData: function appPaymentReplaceListData(data) {
      return request({
        url: '/appPaymentReplaceList/appPaymentReplaceListData',
        method: 'get',
        data: data
      });
    },
    // 支付参数切换新增
    appPaymentReplaceListAdd: function appPaymentReplaceListAdd(data) {
      return request({
        url: '/appPaymentReplaceList/appPaymentReplaceListAdd',
        method: 'post',
        data: data
      });
    },
    // 支付参数切换修改
    appPaymentReplaceListUpdate: function appPaymentReplaceListUpdate(data) {
      return request({
        url: '/appPaymentReplaceList/appPaymentReplaceListUpdate',
        method: 'post',
        data: data
      });
    },
    // 支付参数切换删除
    appPaymentReplaceListDel: function appPaymentReplaceListDel(data) {
      return request({
        url: '/appPaymentReplaceList/appPaymentReplaceListDel',
        method: 'post',
        data: data
      });
    },
    // 支付订单列表
    payOrderList: function payOrderList(data) {
      return request({
        url: '/sdkPayDataController/orderList',
        method: 'get',
        data: data
      });
    }
  };
});