export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    // 路由列表
    routesList: function routesList(data) {
      return request({
        url: '/auth/routes',
        method: 'get',
        data: data
      });
    },
    // 路由列表
    getroutes: function getroutes(data) {
      return request({
        url: '/route/sort',
        method: 'get',
        data: data
      });
    },
    // 删除路由
    delRouter: function delRouter(data) {
      return request({
        url: '/route/delRoute',
        method: 'get',
        data: data
      });
    },
    // 路由详情
    routerDetails: function routerDetails(data) {
      return request({
        url: '/route/upRoute',
        method: 'get',
        data: data
      });
    },
    // 新增路由
    addRouter: function addRouter(data) {
      return request({
        url: '/route/add',
        method: 'post',
        data: data
      });
    },
    // 修改路由
    updataRouter: function updataRouter(data) {
      return request({
        url: '/route/upRoute',
        method: 'post',
        data: data
      });
    },
    // 获取全部路由
    getAllRouter: function getAllRouter(data) {
      return request({
        url: '/route/all',
        method: 'get',
        data: data
      });
    }
  };
});