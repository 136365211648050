export default {
  data: function data() {
    return {
      // 默认
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      // 日 单选
      pickerOptionsDay: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今日',
          onClick: function onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '一周前',
          onClick: function onClick(picker) {
            var date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }, {
          text: '半月前',
          onClick: function onClick(picker) {
            var date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 15);
            picker.$emit('pick', date);
          }
        }]
      },
      // 日 范围选择
      pickerOptionsRadius: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今日',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '半月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      // 月 单选
      pickerOptionsMonth: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '当月',
          onClick: function onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '上月',
          onClick: function onClick(picker) {
            var date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', date);
          }
        }]
      }
    };
  },
  activated: function activated() {},
  methods: {}
};