var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "custom-box",
    {
      attrs: {
        title: "用户",
        visible: _vm.dialogFormVisible,
        modal: false,
        "modal-append-to-body": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: function($event) {
          return _vm.clearData()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "popover",
          attrs: {
            rules: _vm.rules,
            model: _vm.formData,
            "label-width": "60px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { prop: "email", label: "账号" }
            },
            [
              _c("el-input", {
                staticClass: "flex-1",
                attrs: { autocomplete: "off", placeholder: "请输入" },
                model: {
                  value: _vm.formData.email,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "email", $$v)
                  },
                  expression: "formData.email"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { prop: "auth_group_id", label: "角色" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "flex-1",
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formData.auth_group_id,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "auth_group_id", $$v)
                    },
                    expression: "formData.auth_group_id"
                  }
                },
                _vm._l(_vm.optionsoRganization, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { prop: "password", label: "密码" }
            },
            [
              _c("el-input", {
                staticClass: "flex-1",
                attrs: { placeholder: "不填写即不修改", autocomplete: "off" },
                model: {
                  value: _vm.formData.password,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "password", $$v)
                  },
                  expression: "formData.password"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { prop: "nickname", label: "昵称" }
            },
            [
              _c("el-input", {
                staticClass: "flex-1",
                attrs: { placeholder: "不填写即不修改", autocomplete: "off" },
                model: {
                  value: _vm.formData.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "nickname", $$v)
                  },
                  expression: "formData.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "lodingItem",
              attrs: { prop: "status", label: "状态" }
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.status,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1, border: "" } }, [
                    _vm._v("启用")
                  ]),
                  _c("el-radio", { attrs: { label: 0, border: "" } }, [
                    _vm._v("禁用")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { round: "" },
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function($event) {
                  return _vm.dataFormSubmit()
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }